@import url('https://fonts.googleapis.com/css?family=Josefin+Sans|Lato|Open+Sans|Roboto&display=swap');

@tailwind base;
@tailwind components;

.tango-month-rows-5 {
    grid-template-rows: 2rem repeat(5, minmax(9vh, auto))
}
.tango-month-rows-6 {
    grid-template-rows: 2rem repeat(6, minmax(9vh, auto))
}

@tailwind utilities;

@layer base {
    a {
        @apply text-blue-700;
    }
}

@layer utilities {
    @variants responsive {
        .tango-word-break {
            word-break: break-word;
            hyphens: auto;
        }
    }
}
